<script setup>
import {computed} from "vue";
import {BUTTON_KINDS} from "@Res/js/constants";

const props = defineProps({
    href: String,
    as: {
        type: String,
        required: false,
    },
    type: {
        type: String,
        default: "link",
        validator(value) {
            return ["link", "button"].includes(value);
        },
    },
    fit: {
        type: Boolean,
        default: false,
    },
    rounded: {
        type: Boolean,
        default: false,
    },
    size: {
        type: String,
        default: "md",
        validator(value) {
            return ["xs", "sm", "md", "lg", "xl"].includes(value);
        },
    },
    kind: {
        type: String,
        default: "primary",
        validator: kind => BUTTON_KINDS.includes(kind),
    },
});

const mainType = computed(() =>
    props.type === "button" || props.as === "button" ? "button" : "link"
);
</script>

<template>
    <a
        :class="[
            `${mainType} ${mainType}-${kind}`,
            size ? `${mainType}-${size}` : '',
            { 'w-fit': fit },
            { 'rounded-full': rounded },
        ]"
        :href="href"
    >
        <slot/>
    </a>
</template>
